<template>
  <vue-final-modal
    ref="modal"
    :classes="['confirm-country']"
    content-class="confirm-country__content"
    :name="name"
  >
    <mobile-close-line class="confirm-country__mobile-controls" @swipe-down="onSwipeDown" />

    <div class="confirm-country__title">
      {{ $t('selectCountry.confirmCountry.title') }}&nbsp;<b>{{ countryName }}</b
      >{{ $t('selectCountry.confirmCountry.sign') }}
    </div>
    <div class="confirm-country__buttons">
      <base-button primary sm class="confirm-country__button" @click="onConfirm">{{
        $t('selectCountry.confirmCountry.yes')
      }}</base-button>
      <base-button secondary sm class="confirm-country__button" @click="onSelect">{{
        $t('selectCountry.confirmCountry.no')
      }}</base-button>
    </div>
  </vue-final-modal>
</template>

<script>
import stopScrollOpenModal from '@/components/Modal/stop-scroll-open-modal-mixin';
import MobileCloseLine from '@/components/MobileCloseLine/MobileCloseLine.vue';
import BaseButton from '@/components/BaseComponents/BaseButton.vue';
import { COOKIE_CONFIRM_COUNTRY } from '@/constants/cookie-tag';
import SelectCountryModal from '@/components/Modal/SelectCountryModal/SelectCountryModal.vue';

export default {
  name: 'ConfirmCountryModal',
  components: {
    BaseButton,
    MobileCloseLine,
  },
  mixins: [stopScrollOpenModal],
  data() {
    return {
      name: 'confirm-country-modal',
    };
  },
  computed: {
    countryName() {
      return this.$t(`selectCountry.${this.$store.getters.websiteId}`);
    },
  },
  methods: {
    onClose() {
      this.$modal.hide(this.name);
    },
    onSwipeDown() {
      this.onClose();
    },
    onConfirm() {
      this.$cookies.set(COOKIE_CONFIRM_COUNTRY, 1, {
        maxAge: 60 * 60 * 24 * 30, // 30 дней
      });
      this.onClose();
    },
    onSelect() {
      this.onClose();
      this.$modal.show({ component: SelectCountryModal });
    },
  },
};
</script>
<style lang="scss">
.confirm-country {
  display: flex;
  align-items: flex-end;

  &__content {
    width: 100%;
    padding: 16px;
    transition: $primary-transition;
    background-color: #fff;
    position: relative;
    top: 0;
    right: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  @include media-min-lg {
    align-items: center;

    &__content {
      width: auto;
      margin: auto;
      height: auto;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
      position: absolute;
      top: 132px;
      right: 88px;
    }
  }
}
</style>

<style scoped lang="scss">
.confirm-country {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: $primary-transition;
  background-color: #fff;

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    outline: none;

    @include media-min-lg {
      top: 18px;
      right: 13px;
    }
  }

  &__mobile-controls {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000;
    padding: 40px 0;

    @include media-min-lg {
      text-align: left;
      padding: 0;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    @include media-min-lg {
      flex-direction: row;
      margin-top: 16px;
    }
  }

  &__buttons > :first-child {
    margin-bottom: 8px;

    @include media-min-lg {
      margin-bottom: 0;
    }
  }

  &__button {
    text-transform: uppercase;
    margin-right: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;

    @include media-min-lg {
      margin-right: 12px;
      line-height: 12px;
    }
  }
}
</style>
