<template>
  <header
    :class="{
      'layout-header__sticky': isHelpPage,
    }"
  >
    <div class="device-wrapper show-on-mobile">
      <div class="top-line">
        <base-container>
          <div class="inner-wrapper">
            <a class="logo" :href="rootWithLocale" @click="onClick(menu[0])">
              <img v-if="logo?.src" :src="logo.src" alt="CDEK" />
            </a>

            <button class="mobile-toggle__button" @click="menuStore.openMobileMenu">
              <span class="mobile-toggle" :class="{ 'order-notification': ordersHasUpdates }">
                <mobile-drawer-icon />
              </span>
            </button>
          </div>
        </base-container>
      </div>

      <mobile-drawer :cabinet="cabinet" />
    </div>

    <div class="device-wrapper show-on-desktop">
      <base-container class="top-level">
        <div class="top-level__left">
          <a
            v-if="logo && logo.src"
            :href="rootWithLocale"
            class="top-level__logo"
            @click="onClick(menu[0])"
          >
            <img :src="logo.src" alt="CDEK" />
          </a>

          <div v-if="menu.length > 0" ref="container" class="top-level__container">
            <menu-item
              v-for="item in menu"
              :id="`top-menu-${item.id}`"
              :key="item.id"
              :ref="'menu' + item.id"
              :menu="item"
              class="top-level__link"
              :href="item.url"
              :class="{ active: activePath && activePath[0] && activePath[0].id === item.id }"
            >
              {{ item.title }}
            </menu-item>

            <div ref="underline" class="top-level__underline" />
          </div>
        </div>
        <div class="top-level__right">
          <icons-menu />
        </div>
      </base-container>

      <div class="top-level__spacer" />

      <base-container class="mid-level">
        <menu-mid-line :menus="submenu" />
        <div class="mid-level__extra">
          <locale-switcher v-if="displaySwitcher" class="mid-level__switcher" />
          <div v-if="isCabinet" id="header-cabinet-place"></div>
          <div class="mid-level__extra__buttons">
            <a v-if="isRuSite" :href="paymentLink">
              <cdek-button theme="outline" small class="mobile-drawer-first-level__payment">
                {{ $t('menu.links.payment') }}
              </cdek-button>
            </a>
            <client-only>
              <login v-if="enabledLK" />
            </client-only>
          </div>
        </div>
      </base-container>
    </div>
  </header>
</template>

<script>
// TODO: переписать на composition API
import { mapGetters } from 'vuex';
import { storeToRefs } from 'pinia';
import { computed, defineAsyncComponent } from 'vue';
import MobileDrawer from '#root/src/components/MobileDrawer/MobileDrawer.vue';
import BaseContainer from '#root/src/components/BaseComponents/BaseContainer.vue';
import { CdekButton } from '@cdek-ui-kit/vue';
import MenuMidLine from '@/components/Menu/MenuMidLine.vue';
import MenuItem from '@/components/Menu/MenuItem.vue';
import ClientOnly from '@/components/ClientOnly.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import { useMenuStore } from '@/store/pinia/menu/menu';
import IconsMenu from '@/components/IconsMenu/IconsMenu.vue';
import useSiteInfo from '@/composables/use-site-info';
import usePage from '@/composables/use-page';
import useSettings from '@/composables/use-settings';
import MobileDrawerIcon from './icons/profile-icon.svg';

export default {
  name: 'LayoutHeader',

  components: {
    CdekButton,
    IconsMenu,
    LocaleSwitcher,
    ClientOnly,
    MobileDrawerIcon,
    MenuItem,
    MenuMidLine,
    MobileDrawer,
    BaseContainer,
    Login: defineAsyncComponent(() => import('@/components/Login/Login.vue')),
  },
  props: {
    logo: {
      type: Object,
      required: true,
    },
    cabinet: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  setup() {
    const menuStore = useMenuStore();

    const { menu, activePath, activeMenu, individualsSubmenu } = storeToRefs(menuStore);

    const { isRuSite, currentLocale } = useSiteInfo();

    const { isHelpPage } = usePage();

    const paymentLink = computed(() => {
      return `/${currentLocale.value}/payment`;
    });

    const rootWithLocale = computed(() => {
      return `/${currentLocale.value}/`;
    });

    const { getShowLangSwitcher } = useSettings();

    const isShowLangSwitcher = getShowLangSwitcher();

    return {
      menu,
      activePath,
      activeMenu,
      individualsSubmenu,
      menuStore,
      paymentLink,
      isRuSite,
      rootWithLocale,
      isHelpPage,
      isShowLangSwitcher,
    };
  },
  computed: {
    ...mapGetters({
      screen: 'resize/screen',
      isActiveCdekId: 'isActiveCdekId',
      ordersHasUpdates: 'cabinet/ordersHasUpdates',
      enabledLK: 'auth/enabledLK',
    }),
    activeFirstLevelMenu() {
      return this.activePath[0] || null;
    },
    submenu() {
      if (this.activeFirstLevelMenu?.children?.length) {
        return this.activeFirstLevelMenu.children;
      }

      return this.individualsSubmenu;
    },
    menuContainer() {
      return this.$refs.container;
    },
    underline() {
      return this.$refs.underline;
    },
    isCabinet() {
      return this.cabinet;
    },
    displaySwitcher() {
      return !this.cabinet && this.isShowLangSwitcher;
    },
  },

  watch: {
    activeMenu: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.updateActiveUnderline();
        });
      },
    },
    screen: {
      immediate: true,
      handler(newVal, oldVal = {}) {
        const { desktop: newDesktop } = newVal;
        const { desktop: oldDesktop } = oldVal;

        if (newDesktop === oldDesktop) {
          return;
        }

        if (newDesktop) {
          this.updateActiveUnderline();
        }

        this.menuStore.closeMobileMenu();
      },
    },
  },

  mounted() {
    this.updateUnderlineOnResize();
    window.addEventListener('resize', this.updateUnderlineOnResize);
    this.loadCdekAuthScript();
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateUnderlineOnResize);
  },

  methods: {
    updateActiveUnderline() {
      if (!this.activeFirstLevelMenu || !this.screen.desktop) {
        return;
      }

      const idx = this.activeFirstLevelMenu.id;
      if (!this.$el) {
        return;
      }

      const currentMenu = this.$el.querySelector(`#top-menu-${idx}`);

      if (currentMenu) {
        const { underline } = this.$refs;
        if (!underline) {
          return;
        }

        const el = currentMenu;

        const rect = el.getBoundingClientRect();
        const containerRect = this.menuContainer.getBoundingClientRect();

        const translateX = rect.x - containerRect.x;

        underline.style.transform = `translateX(${translateX}px)`;
        underline.style.width = `${rect.width}px`;
      } else {
        setTimeout(this.updateActiveUnderline, 100);
      }
    },

    onClick(menu) {
      if (menu) {
        this.menuStore.setActiveMenu(menu);
      }
    },

    toggleMobileMenu() {
      this.menuStore.openMobileMenu();
    },

    updateUnderlineOnResize() {
      this.updateActiveUnderline();
    },
    loadCdekAuthScript() {
      const url = import.meta.env.VITE_CDEK_AUTH_SCRIPT_URL;

      if (this.isLoggedInCabinet) {
        return;
      }

      const script = Object.assign(document.createElement('script'), {
        async: true,
        src: url,
      });
      document.body.appendChild(script);
    },
  },
};
</script>

<style lang="scss" scoped>
.mid-level,
.top-level,
.top-level__container {
  display: flex;
  align-items: center;
}

.top-level {
  align-items: center;
  justify-content: space-between;

  &__underline {
    position: absolute;
    bottom: -1px;
    transition: $primary-transition;
    border-bottom: 2px solid $primary-base;
  }

  &__spacer {
    height: 1px;
    box-shadow: inset 0 -1px 0 $ui-gray;
  }

  &__link {
    padding: 12px;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $grey-transparent;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    &.active {
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    }
  }

  &__logo {
    display: inline-flex;
    flex: 0 0 auto;
    width: 72px;
    height: 20px;
    max-height: 28px;
    margin: auto 24px auto 0;

    & > img {
      width: 100%;
      height: auto;
    }
  }

  &__left {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

.mid-level {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 22px;

  &__extra {
    display: flex;
    align-items: center;

    &__buttons {
      display: flex;
      gap: 16px;

      a {
        &:deep(.cdek-button) {
          @include button-1;

          width: 164px;
          padding: 6px 12px;
          letter-spacing: 0.4px;
        }
      }
    }
  }

  &__switcher {
    margin: 0 12px;
  }
}

.mobile-toggle__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.mobile-toggle.order-notification {
  position: relative;

  &::after {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 12px;
    height: 12px;
    content: '';
    border: 2px solid $WhiteColor;
    border-radius: 12px;
    background: $ui-orange-base;
  }
}

.layout-wrapper {
  .layout-header__sticky {
    @include media-min-lg {
      position: sticky;
      top: 0;
    }
  }
}
</style>
