import { useStore } from 'vuex';
import { computed } from 'vue';

const useCabinetData = () => {
  const store = useStore();

  const fullName = computed(() => store.getters['cabinet/fullName']);

  const getLoginPhone = computed(() => store.getters['cabinet/getLoginPhone']);

  return { fullName, getLoginPhone };
};

export default useCabinetData;
