export const getHeadElement = () => {
  return document.getElementsByTagName('head')[0];
};

export const createScriptElement = () => {
  return document.createElement('script');
};

export const getBodyElement = () => {
  return document.querySelector('body');
};
