<template>
  <div>
    <mobile-drawer-first-level :cabinet="cabinet" @second-level-expanded="onExpanded($event)" />
    <mobile-drawer-second-level
      :selected-menu="selectedSubmenu"
      @second-level-closed="selectedSubmenu = null"
    />
  </div>
</template>

<script>
import MobileDrawerFirstLevel from '#root/src/components/MobileDrawer/MobileDrawerFirstLevel.vue';
import MobileDrawerSecondLevel from '#root/src/components/MobileDrawer/MobileDrawerSecondLevel.vue';
import { storeToRefs } from 'pinia';
import { useMenuStore } from '@/store/pinia/menu/menu';

export default {
  name: 'MobileDrawer',
  components: {
    MobileDrawerSecondLevel,
    MobileDrawerFirstLevel,
  },
  props: {
    cabinet: Boolean,
    mobileMenu: Boolean,
  },
  setup() {
    const menuStore = useMenuStore();

    const { menus, isMobileMenuOpen } = storeToRefs(menuStore);

    return { menus, isMobileMenuOpen };
  },
  data() {
    return { selectedSubmenu: null };
  },
  watch: {
    isMobileMenuOpen(val) {
      if (val) {
        document.documentElement.classList.add('mobile-menu-opened');
        return;
      }

      document.documentElement.classList.remove('mobile-menu-opened');
      this.selectedSubmenu = null;
    },
  },
  methods: {
    onExpanded(menus) {
      this.selectedSubmenu = menus;
    },
  },
};
</script>
