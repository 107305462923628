/**
 * $ui-base =        #00A942
 * $ui-red-base =    #FA4747
 * $ui-orange-base = #FF6B00
 * $ui-yellow-base = #FFCB07
 * $ui-black =       #434C5D
 * $ui-dark-gray =   #ADAFB4
 * $ui-gray =        #E0E0E0
 * $ui-gray-1 =      #B0B0B0
 * $ui-light-gray =  #F2F2F2
 * $ui-light-blue =  #F6F7F9
 * $ui-white =       #FFFFFF
 */

export const $uiBaseColor = {
  base: '#00A942',
  redBase: '#FA4747',
  orangeBase: '#FF6B00',
  yellowBase: '#FFCB07',
  black: '#434C5D',
  darkGray: '#ADAFB4',
  gray: '#E0E0E0',
  gray1: '#B0B0B0',
  lightGray: '#F2F2F2',
  lightBlue: '#F6F7F9',
  white: '#FFFFFF',
  primaryColor: '#1AB248',
};
