<template>
  <div class="mid-level-menu">
    <template v-if="menus.length > 0">
      <div v-for="(menu, idx) in menus" :key="getKey(menu.id)" class="mid-level-menu__item">
        <VDropdown
          :distance="12"
          placement="bottom-start"
          popper-class="header-popover"
          :delay="{ show: 100 }"
          @apply-show="onPopoverOpen(menu.children, idx)"
          @hide="onPopoverClose()"
        >
          <menu-item
            :menu="menu"
            :tag="menu.children && menu.children.length && 'button'"
            class="mid-level-menu__button"
            :class="{
              active:
                idx === popoverIdx || (activePath && activePath[1] && activePath[1].id === menu.id),
            }"
          >
            {{ menu.title }}

            <menu-new-label v-if="menu.isNew" class="mid-level-menu__label" />

            <menu-dropdown-arrow
              v-if="menu.children && menu.children.length"
              class="mid-level-menu__arrow"
              :is-open="idx === popoverIdx"
            />
          </menu-item>

          <template v-if="selectedMenus.length > 0" #popper>
            <div
              class="popover-menu__container"
              :class="{
                'popover-menu__container__four': isFourLvl,
                'popover-menu__container__icons': hasIcons,
              }"
            >
              <menu-popover-item
                v-for="ch in selectedMenus"
                :key="ch.id"
                v-close-popper
                :menu="ch"
                class="popover-menu__item"
                :has-icons="hasIcons"
              />
            </div>
          </template>
        </VDropdown>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useMenuStore } from '@/store/pinia/menu/menu';
import { storeToRefs } from 'pinia';
import { computed, nextTick, ref, watch } from 'vue';
import IMenuItem from '@/types/dto/menuItem';
import MenuItem from '@/components/Menu/MenuItem.vue';
import MenuNewLabel from '@/components/Menu/MenuNewLabel.vue';
import MenuDropdownArrow from '@/components/Menu/MenuDropdownArrow.vue';
import MenuPopoverItem from '@/components/Menu/MenuPopoverItem.vue';
import useSiteInfo from '@/composables/use-site-info';
import { usePageContext } from '@/composables/use-page-context';

interface IMenuMidLine {
  menus: IMenuItem[];
}

defineProps<IMenuMidLine>();

const menuStore = useMenuStore();
const { activePath } = storeToRefs(menuStore);

const popoverIdx = ref<number | null>(null);
const selectedMenus = ref<IMenuItem[]>([]);
const isFourLvl = ref(false);

const { isAuthorizedUser } = usePageContext();

const { isLoggedInCabinet } = useSiteInfo();

const onPopoverOpen = async (menus: IMenuItem[], idx: number) => {
  await nextTick();
  selectedMenus.value = menus;
  popoverIdx.value = idx;
};

const onPopoverClose = () => {
  popoverIdx.value = null;
};

const hasIcons = computed(() => {
  return selectedMenus.value.some((el) => !!el.icon);
});

watch(
  selectedMenus,
  () => {
    isFourLvl.value = selectedMenus.value.some((menu) => {
      return menu.children.length > 0;
    });
  },
  { deep: true },
);

const getKey = (id: number) => {
  if (isLoggedInCabinet.value || isAuthorizedUser) {
    return `isAuthorized-${id}`;
  }

  return id;
};
</script>

<style scoped lang="scss">
.mid-level-menu {
  display: flex;
  align-items: center;
  height: 36px;
  gap: 20px;

  &__item {
    display: flex;
  }

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: $primary-transition;
    text-decoration: none;
    color: $base-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:hover,
    &.active {
      color: $Primary_Active;

      :deep(path) {
        stroke: $Primary_Active;
        stroke-opacity: 1;
      }
    }
  }

  &__arrow {
    margin-left: 4px;
  }

  &__label {
    margin: 0 0 0 8px;
  }
}

.popover-menu {
  &__container {
    display: inline-flex;
    align-items: flex-start;
    padding: 8px;
    flex-wrap: wrap;
    column-gap: 16px;
    max-width: 100%;
    width: 100%;

    &__four {
      gap: 16px;
    }

    &__icons {
      width: 744px;
    }
  }
}
</style>

<style lang="scss">
.header-popover.v-popper--theme-dropdown {
  .v-popper__arrow-container {
    display: none;
  }

  .v-popper__inner {
    display: none;
    border: none;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
    background: $Peak;
    min-width: 584px;
    max-width: 744px;
    width: min-content;

    // Не знаю как иначе добраться до этого элемента, в доке тултипа не нашел для него класса
    & > div {
      display: flex;
    }

    @include media-min-xl {
      display: block;
    }
  }
}
</style>
