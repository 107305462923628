<template>
  <div
    class="help-customer-trigger"
    :class="{
      'help-customer-trigger--closed': !open,
      'help-customer-trigger--opened': open,
    }"
    @click="onTrigger"
  >
    <div v-if="open">
      <icon-close :fill="'#fff'" />
    </div>
    <div v-if="!open" class="help-customer-trigger__default">
      <div class="help-customer-trigger__default-text" v-html="buttonName" />
      <icon-messages class="help-customer-trigger__message" />
    </div>
  </div>
</template>

<script>
import { reachGoal } from '@/utils/reach-goal';
import IconClose from '../icon-svg/IconClose.vue';
import IconMessages from './icons/messages.svg';

export default {
  name: 'HelpCustomerTrigger',
  components: {
    IconClose,
    IconMessages,
  },
  props: {
    opened: Boolean,
    widget: { type: Object, default: null },
  },
  emits: ['toggle'],
  data() {
    return {
      open: false,
    };
  },
  computed: {
    buttonName() {
      return (this.widget && this.widget.allQuestionTitle) || '';
    },
  },
  watch: {
    opened(opened) {
      this.open = opened;
    },
  },
  methods: {
    onTrigger() {
      this.open = !this.open;
      if (this.open) {
        reachGoal('widget_click');
      }
      this.$emit('toggle', this.open);
    },
  },
};
</script>

<style scoped lang="scss">
.help-customer-trigger {
  background-color: #00bc4c;
  border-radius: 25px;
  color: white;
  cursor: pointer;

  &--closed {
    padding: 8px 16px 8px 24px;
    animation: shadow-pulse 1s infinite;
  }

  &--opened {
    line-height: 0;
    padding: 17px;
  }

  &__default {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__default-text {
    margin-right: 8px;

    @media (min-width: $tablet) {
      margin-right: 12px;
    }
  }

  &__message {
    width: 28px;

    @media (min-width: $tablet) {
      width: 33px;
    }
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 188, 76, 0.4);
  }

  100% {
    box-shadow: 0 0 0 16px rgba(0, 188, 76, 0);
  }
}
</style>
