<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    class="icon"
    :class="{ open: isOpen }"
  >
    <path
      d="M4 6L8 10L12 6"
      :stroke="color"
      :stroke-opacity="isOpen ? 1 : 0.5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'MenuDropdownArrow',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    color() {
      // Разные написания цветов - из фигмы
      return this.isOpen ? '#158E3A' : 'black';
    },
  },
};
</script>

<style scoped lang="scss">
.icon {
  transition: $primary-transition;
  flex-shrink: 0;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
