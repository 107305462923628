export const SET_SHOW_CONFIRM_CITY = 'SET_SHOW_CONFIRM_CITY';

export const FOOTER_TYPE = {
  SET_FOOTER: 'SET_FOOTER',
  LOADING_FOOTER: 'LOADING_FOOTER',
  START_LOADING_FOOTER: 'START_LOADING_FOOTER',
  STOP_LOADING_FOOTER: 'STOP_LOADING_FOOTER',
};

export const CLIENT_QUESTION_TYPE = {
  SET_HASH: 'SET_HASH',
};

export const CONTRACT_TYPE = {
  UPDATE_FORM: 'UPDATE_FORM',
};

export const LOYALTY_TYPE = {
  SET_AVAILABLE_BONUSES: 'SET_AVAILABLE_BONUSES',
  SET_TEXTS: 'SET_TEXTS',
  SET_BURNING_BONUSES: 'SET_BURNING_BONUSES',
  GET_BONUSES: 'GET_BONUSES',
  SET_SMS_SUBSCRIPTION: 'SET_SMS_SUBSCRIPTION',
};
