<template>
  <slide-left-transition>
    <div v-if="isMobileMenuOpen" class="mobile-drawer-first-level">
      <mobile-drawer-header>
        <locale-switcher v-if="isShowLangSwitcher" />
      </mobile-drawer-header>

      <div class="mobile-drawer-first-level__buttons">
        <mobile-drawer-user :cabinet="cabinet" />

        <div v-if="isRuSite" class="mobile-drawer-first-level__payment_wrapper">
          <a :href="paymentLink" @click="closeMenu">
            <cdek-button theme="outline" small class="mobile-drawer-first-level__payment">
              {{ $t('menu.links.payment') }}
            </cdek-button>
          </a>
        </div>
      </div>

      <icons-menu />

      <div class="mobile-drawer__menu">
        <mobile-drawer-menu-item
          v-for="(rootMenu, index) in menus"
          :key="index"
          :root-menu="rootMenu"
          class="mobile-drawer__menu-item"
          @expanded="onExpanded"
        />
      </div>
    </div>
  </slide-left-transition>
</template>

<script setup lang="ts">
import MobileDrawerHeader from '#root/src/components/MobileDrawer/MobileDrawerHeader.vue';
import SlideLeftTransition from '#root/src/components/Transitions/SlideLeftTransition.vue';
import MobileDrawerMenuItem from '#root/src/components/MobileDrawer/MobileDrawerMenuItem.vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { CdekButton } from '@cdek-ui-kit/vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import { useMenuStore } from '@/store/pinia/menu/menu';
import type IMenuItem from '@/types/dto/menuItem';
import IconsMenu from '@/components/IconsMenu/IconsMenu.vue';
import useSiteInfo from '@/composables/use-site-info';
import useSettings from '@/composables/use-settings';
import MobileDrawerUser from './MobileDrawerUser.vue';

interface IMobileDrawerFirstLevel {
  cabinet: boolean;
}

defineProps<IMobileDrawerFirstLevel>();

const emit = defineEmits<{
  (e: 'second-level-expanded', submenu: IMenuItem): void;
}>();

const menuStore = useMenuStore();

const { menus, isMobileMenuOpen } = storeToRefs(menuStore);

const { getShowLangSwitcher } = useSettings();

const isShowLangSwitcher = getShowLangSwitcher();

const { isRuSite, currentLocaleISO } = useSiteInfo();

const paymentLink = computed(() => {
  return `/${currentLocaleISO.value}/payment`;
});

const closeMenu = () => {
  menuStore.closeMobileMenu();
};

const onExpanded = (submenu: IMenuItem) => {
  emit('second-level-expanded', submenu);
};
</script>
<style scoped lang="scss">
.mobile-drawer__menu {
  margin-top: 24px;
}

.mobile-drawer-first-level {
  &__payment_wrapper {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 24px;

    @include media-min-sm {
      max-width: 320px;
    }
  }
}

.mobile-drawer-first-level__dogovor {
  margin: 12px 16px;
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss">
.mobile-drawer-first-level {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  background-color: #fff;

  .menu-city-title {
    text-align: right;
    color: #a5a5a5;
    font-size: 16px;
    line-height: 19px;
  }

  .menu-city-value {
    display: inline-block;
    font-size: 16px;
  }

  .menu-city {
    width: auto;
    margin: 24px 16px;
    padding-left: 0;
  }
}
</style>
