<template>
  <div id="flomni" :class="{ 'flomni-inline containerMF': inline, flomni: !inline }" />
</template>

<script>
import { mapState } from 'vuex';
import { onBeforeUnmount, onMounted } from 'vue';
import useFlomni from '@/composables/use-flomni';
import useSiteInfo from '@/composables/use-site-info';

export default {
  props: {
    inline: Boolean,
  },
  setup() {
    const { isLoggedInCabinet } = useSiteInfo();
    const { displayChat, installFlomni } = useFlomni();

    const destroy = () => {
      if (window.$flomni) {
        // Уничтожает инстанс компонента, но почему то фломни остается в состоянии загрузки
        // поэтому ниже я еще контент селектора удаляю.
        window.$flomni.chat && window.$flomni.chat.destroy();

        const flomniEl = document.getElementById('flomni');

        if (flomniEl) {
          flomniEl.innerHTML = '';
        }
      }
    };

    const refresh = () => {
      if (displayChat.value) {
        if (window.$flomni) {
          window.$flomni.chat.create();
        } else {
          this.installFlomni();
        }
      } else {
        this.destroy();
      }
    };

    onMounted(() => {
      if (displayChat.value) {
        installFlomni();
      }
    });

    onBeforeUnmount(destroy);

    return { installFlomni, destroy, refresh, displayChat, isLoggedInCabinet };
  },
  computed: {
    ...mapState('flomni', ['isHelpCustomerLoaded']),
  },
  watch: {
    displayChat() {
      setTimeout(() => this.refresh(), 50);
    },
    inline(inline) {
      if (inline !== undefined) {
        this.destroy();
        window.$flomni = undefined;
        setTimeout(() => this.refresh(), 50);
      }
    },
    isHelpCustomerLoaded() {
      // flomni-mounted используется только для HelpCustomer, поэтому ожидаем что он загрузился
      this.$emitter.emit('flomni-mounted');
    },
  },
  mounted() {
    // костыль для авторизованного (хф), потому что эмит почему не работает в вотчере
    if (this.isHelpCustomerLoaded && this.isLoggedInCabinet) {
      this.$emitter.emit('flomni-mounted');
    }
  },
};
</script>

<style lang="scss" scoped>
.flomni-inline {
  position: relative;
  z-index: 1;
  width: 100%;

  & iframe {
    min-height: 500px !important;

    @media (min-width: $tablet) {
      min-height: 600px !important;
    }

    @media (min-width: $desktop) {
      min-height: 800px !important;
    }
  }
}

.flomni {
  position: relative;
  z-index: 100;
}
</style>
