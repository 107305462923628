<template>
  <footer class="footer" :class="footerClass">
    <base-container>
      <base-layout column wrap>
        <base-layout wrap>
          <base-flex class="footer__menu lg4 md5 sm8 xs12 order-md2 order-xs2">
            <footer-menu v-if="!loading" />
          </base-flex>

          <base-flex class="footer__additional lg4 md3 sm4 xs12 order-md2 order-xs3">
            <footer-additional-links
              v-if="!loading && additionalLinks.length"
              :links="additionalLinks"
            />
          </base-flex>
          <base-flex d-flex class="lg4 md4 sm12 xs12 order-md2 order-sm1 order-xs1">
            <base-layout class="footer__search" :class="{ cn: isChine }">
              <base-flex class="footer__search-box md12 sm6 xs12 order-md1 order-sm2 order-xs2">
                <footer-search v-if="!loading" :placeholder="footer.search" />
              </base-flex>

              <base-flex
                v-if="isChine"
                class="md12 sm6 xs12 order-md2 order-xs2 order-sm2 qr-codes"
              >
                <img :src="qr1" alt="mini app QR" class="qr-codes-code" />
                <img :src="qr2" alt="WeChat QR" class="qr-codes-code" />
              </base-flex>
              <base-flex v-if="!isChine" class="md12 sm6 xs12 order-md2 order-sm1 order-xs1">
                <footer-feedback v-if="!loading" :feedback="footer.feedback" />
              </base-flex>
            </base-layout>
          </base-flex>
        </base-layout>
        <footer-international
          v-if="!loading && footer.links.international && footer.links.international.length"
          :international="footer.links.international"
        />
        <base-layout wrap>
          <base-flex v-if="!loading" class="md6 sm12 xs12">
            <footer-social-group-links v-if="isShowSocialLinks" :social="footer.links.social" />
          </base-flex>
          <base-flex v-if="!loading" class="md6 sm12 xs12">
            <footer-official
              v-if="footer.links.official && footer.links.official.length"
              :official="footer.links.official"
            />
          </base-flex>
        </base-layout>
        <base-flex>
          <footer-copy />
        </base-flex>
      </base-layout>
    </base-container>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import { computed, defineAsyncComponent } from 'vue';

import BaseContainer from '@/components/BaseContainer.vue';
import BaseLayout from '@/components/BaseLayout.vue';
import BaseFlex from '@/components/BaseComponents/BaseFlex.vue';
import usePage from '@/composables/use-page';

export default {
  components: {
    BaseFlex,
    BaseLayout,
    BaseContainer,
    FooterMenu: defineAsyncComponent(() => import('./FooterMenu.vue')),
    FooterAdditionalLinks: defineAsyncComponent(() => import('./FooterAdditionalLinks.vue')),
    FooterSearch: defineAsyncComponent(() => import('./FooterSearch.vue')),
    FooterFeedback: defineAsyncComponent(() => import('./FooterFeedback.vue')),
    FooterSocialGroupLinks: defineAsyncComponent(() => import('./FooterSocialGroupLinks.vue')),
    FooterInternational: defineAsyncComponent(() => import('./FooterInternational.vue')),
    FooterOfficial: defineAsyncComponent(() => import('./FooterOfficial.vue')),
    FooterCopy: defineAsyncComponent(() => import('./FooterCopy.vue')),
  },
  setup() {
    const { isNewDesign, isSearchResultsPage } = usePage();

    const footerClass = computed(() => {
      return {
        footer__enable_margin: isNewDesign.value || isSearchResultsPage.value,
      };
    });
    return {
      footerClass,
    };
  },
  data: () => ({
    qr1: '../../assets/img/qr_cn1.jpeg',
    qr2: '../../assets/img/qr_cn2.jpg',
  }),
  computed: {
    ...mapGetters({
      footer: 'footer/footer',
      loading: 'footer/loading',
      isChine: 'isChineSite',
    }),
    additionalLinks() {
      return this.footer.links.additional ?? [];
    },
    isShowSocialLinks() {
      return this.footer.links.social && Object.keys(this.footer.links.social).length;
    },
  },
};
</script>

<style lang="scss" src="./footer.scss"></style>

<style lang="scss" scoped>
.footer__enable_margin {
  margin-top: 48px;

  @include media-min-lg {
    margin-top: 80px;
  }
}
</style>
