<template>
  <div class="layout-wrapper public-site">
    <progress-bar />
    <layout-header v-bind="headerSettings" />
    <client-only>
      <Notifications
        group="notification-error"
        position="top right"
        :max="3"
        classes="notification"
        width="100%"
      />
    </client-only>
    <main
      id="work-area"
      class="work-area"
      :class="{
        'layout-wrapper__work-area_help': isHelpPage,
        'work-area--dark': isDarkPage,
      }"
    >
      <slot />
    </main>

    <footer-main />

    <client-only>
      <flomni-chat v-if="!isLoggedInCabinet || loginPhone" :inline="inlineFlomni" />
    </client-only>

    <client-only v-if="hasConsetToUseOfCookie">
      <cookie-alert />
    </client-only>

    <client-only>
      <help-customer-wrapper v-if="settingsAll.displayHelpCustomer" />
    </client-only>

    <div
      class="mobile-menu-overlay"
      :class="{ 'mobile-menu-overlay--showed': isMobileMenuOpen }"
      @click="clickModalOverlay"
    />

    <client-only> <modals-container /> </client-only>
  </div>
</template>

<script>
import ProgressBar from '#root/src/components/ProgressBar';
import LayoutHeader from '#root/src/components/Layout/LayoutHeader.vue';
import layoutMixin from '#root/src/mixins/layout.mixin';
import { mapGetters } from 'vuex';
import { inject, defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import layoutHeaderMixin from '@/mixins/layout-header.mixin';
import FooterMain from '@/components/Footer/FooterMain.vue';
import ClientOnly from '@/components/ClientOnly.vue';
import CookieAlert from '@/components/CookieAlert.vue';
import HelpCustomerWrapper from '@/components/HelpCustomer/HelpCustomerWrapper.vue';
import FlomniChat from '@/components/FlomniChat/FlomniChat.vue';
import ConfirmCountryModal from '@/components/Modal/SelectCountryModal/ConfirmCountryModal.vue';
import { COOKIE_CONFIRM_COUNTRY, IS_AUTH_COOKIE_TAG } from '@/constants/cookie-tag';
import { castEnv } from '@/utils/env-utils';
import { COUNTRIES } from '@/constants/select-countries';
import { getLocalCity } from '@/utils/menus.utils';
import { useMenuStore } from '@/store/pinia/menu/menu';
import usePage from '@/composables/use-page';
import useLoadUser from '@/components/Login/use-load-user';
import useSiteInfo from '@/composables/use-site-info';
import { sendParams } from '@/utils/reach-goal';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    FlomniChat,
    HelpCustomerWrapper,
    CookieAlert,
    ProgressBar,
    LayoutHeader,
    FooterMain,
    ClientOnly,
  },
  mixins: [layoutMixin, layoutHeaderMixin],
  setup() {
    const pageContent = inject('pageContent', '');
    const { isHelpPage, isDarkPage } = usePage();
    const { load: loadUser } = useLoadUser();
    const menuStore = useMenuStore();

    const { isMobileMenuOpen } = storeToRefs(menuStore);
    const { currentLocale, isLoggedInCabinet } = useSiteInfo();
    return {
      isDarkPage,
      pageContent,
      isMobileMenuOpen,
      menuStore,
      isHelpPage,
      loadUser,
      currentLocale,
      isLoggedInCabinet,
    };
  },
  computed: {
    ...mapGetters({
      inlineFlomni: 'flomni/inline',
      loginPhone: 'cabinet/getLoginPhone',
    }),
  },
  beforeMount() {
    // Нужно вызвать максимально первым
    sendParams({ user_auth: this.isLoggedInCabinet });
  },
  mounted() {
    if (!this.settingsAll.displayHelpCustomer) {
      // TODO: разобрарться нужно ли это, так как pageContent провайдится ниже, и в данным момент pageContent всегда пустая строка
      if (/id="(flomni|flomni-inline)"/.test(this.pageContent)) {
        this.$store.commit('flomni/onInit', {
          displayChat: true,
          inline: /id="flomni-inline"/.test(this.pageContent),
        });
      } else {
        this.$store.commit('flomni/onInit', { displayChat: false });
      }
    }

    this.$apiClient.cabinet.setLocale(this.currentLocale);

    if (this.isLoggedInCabinet) {
      this.loadUser({ withOrderNotification: true });
    } else {
      // Удаляем на всякий случай, для неавторизованного, так как возможно где-то не удалилась
      this.$cookies.remove(IS_AUTH_COOKIE_TAG);
    }

    const countryCode = getLocalCity()?.country_code || this.$store.getters.websiteId;
    const isShow = castEnv(import.meta.env.VITE_SHOW_SELECT_COUNTRY);
    if (
      isShow &&
      !this.$cookies.isKey(COOKIE_CONFIRM_COUNTRY) &&
      countryCode !== this.$store.getters.websiteId &&
      COUNTRIES.find((el) => el.code === countryCode)
    ) {
      this.$modal.show({
        component: ConfirmCountryModal,
      });
    }
  },
});
</script>

<style lang="scss" scoped>
.layout-wrapper {
  .layout-wrapper__work-area_help {
    padding-top: 60px;

    @include media-min-lg {
      padding-top: 0;
    }
  }
}
</style>
