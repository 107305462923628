<template>
  <component :is="tagToUse" class="base-flex" :class="classes"> <slot /></component>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  dFlex: {
    type: Boolean,
    default: false,
  },
});

const tagToUse = computed(() => {
  return props.tag || 'div';
});

const classes = computed(() => {
  const classArray: string[] = [];

  if (props.dFlex) {
    classArray.push('base-d-flex');
  }

  return classArray;
});
</script>

<style lang="scss">
.base-flex {
  flex: 1 1 auto;
}

.base-d-flex {
  display: flex;
}
</style>
