import { useStore } from 'vuex';
import useSiteInfo from '@/composables/use-site-info';
import useCabinetData from '@/composables/use-cabinet-data';
import { computed, onMounted, Ref, ref } from 'vue';
import axios from 'axios';
import { IButtonData } from '@/components/HelpCustomer/types';

import messenger from '@/static/messengers/messenger.svg?url';
import vk from '@/static/messengers/vk.svg?url';
import tg from '@/static/messengers/tg.svg?url';
import vb from '@/static/messengers/vb.svg?url';
import wa from '@/static/messengers/wa.svg?url';
import wa_az from '@/static/messengers/wa_az.svg?url';
import { isAppleDevice } from '@/utils/bowser';
import useSettings from '@/composables/use-settings';

export const openFlomni = () => {
  if (window?.$flomni?.chat) {
    window.$flomni.chat.toggle(true);
  }
};

export const openFlomniByClick = (event: MouseEvent) => {
  event?.preventDefault();
  openFlomni();
};

const showFlomniComponent = () => {
  const store = useStore();
  store.commit('flomni/setIsExternalOpened', true);
};

export const showFlomniComponentByClick = (event: MouseEvent) => {
  event?.preventDefault();
  showFlomniComponent();
};

const useFlomni = () => {
  let flomniLinks: NodeListOf<HTMLElement>;

  const store = useStore();

  const { getSettingsGroup } = useSettings();

  const { returnToMessengers, flomniConfig } = getSettingsGroup('all');
  const { isAzSite } = useSiteInfo();

  const isFlomniScriptLoaded = ref(false);

  const { isLoggedInCabinet } = useSiteInfo();
  const { fullName, getLoginPhone } = useCabinetData();
  const displayChat = computed(() => store.getters['flomni/displayChat']);

  const messengerButtons: Ref<any> = ref([]);

  const isIOS = ref(false);

  const showFlomniComponent = () => {
    store.commit('flomni/setIsExternalOpened', true);
  };

  const showFlomniComponentByClick = (event: MouseEvent) => {
    event?.preventDefault();
    showFlomniComponent();
  };

  const updateState = (state: string) => {
    store.commit('flomni/onUpdateState', { state });
  };

  const getFlomniConfig = () => {
    return flomniConfig;
  };
  const getFlomniClientKey = () => {
    const config = getFlomniConfig();
    return config?.clientKey ?? import.meta.env.VITE_FLOMNI_KEY;
  };

  const clientKey = computed(() => getFlomniClientKey());

  const installFlomni = () => {
    const onToggle = (state: string) => {
      if (window.$flomni && state === 'open') {
        window.$flomni.chat.switchToggler(true);
      }

      updateState(state);
    };

    const src = 'https://i.v2.flomni.com/chat.corner.js';

    const defaultConfig = {
      clientKey: clientKey.value,
      onlyApiWay: false,
      rootClasses: 'non_authed',
      onToggle,
    };

    const config = { ...defaultConfig, ...flomniConfig };

    if (isLoggedInCabinet.value) {
      delete config.rootClasses;
      config.visitorAttributes = {
        login: getLoginPhone.value,
      };

      const userName = fullName.value?.trim();
      if (userName) {
        config.visitorAttributes.userName = userName;
      }
    }

    window.flomniConfig = config;

    const params: any = {
      type: 'text/javascript',
      charset: 'utf-8',
      id: 'flomniChat',
      async: true,
      onload: () => {
        isFlomniScriptLoaded.value = true;
      },
    };

    params.src = src;
    const script = Object.assign(document.createElement('script'), params);
    document.body.appendChild(script);
  };

  const messengerList = () => {
    if (isAzSite.value) {
      messengerButtons.value = [
        {
          icon: wa,
          name: 'WhatsApp',
          link: 'https://api.whatsapp.com/send?phone=994512321881',
          messenger: 'waba',
          qr: {
            url: wa_az,
          },
        },
      ];
      return;
    }

    const url = `https://link.v2.flomni.com/widget/v2/settings/${
      import.meta.env.VITE_FLOMNI_KEY
    }?type=linker.standalone`;
    axios.get(url).then((res) => {
      const visibleButtons = res.data.messengers.filter((m: IButtonData) => {
        if (m.messenger === 'abc' && !isIOS.value && returnToMessengers) {
          return false;
        }

        return m.visible;
      });

      messengerButtons.value = visibleButtons.map((b: IButtonData) => {
        let icon;
        let name = '';

        switch (b.messenger) {
          case 'abc':
            icon = messenger;
            name = returnToMessengers ? 'Messages' : 'Чат';
            break;
          case 'fb':
            icon = messenger;
            name = 'Facebook';
            break;
          case 'vk':
            icon = vk;
            name = 'VK';
            break;
          case 'tlgr':
            icon = tg;
            name = 'Telegram';
            break;
          case 'vbr':
            icon = vb;
            name = 'Viber';
            break;
          case 'waba':
            icon = wa;
            name = 'WhatsApp';
            break;
          default:
            icon = messenger;
            name = 'Messages';
        }
        return {
          ...b,
          icon,
          name,
        };
      });
    });
  };

  onMounted(() => {
    isIOS.value = isAppleDevice();
  });

  const addListenersToLinks = () => {
    flomniLinks = document.querySelectorAll("a[href='#flomni']");

    for (const link of flomniLinks) {
      link.addEventListener('click', showFlomniComponentByClick);
    }
  };

  const removeListenersFromLinks = () => {
    for (const link of flomniLinks) {
      link.removeEventListener('click', showFlomniComponentByClick);
    }
  };

  return {
    showFlomniComponent,
    showFlomniComponentByClick,
    addListenersToLinks,
    removeListenersFromLinks,
    installFlomni,
    displayChat,
    getFlomniConfig,
    getFlomniClientKey,
    clientKey,
    messengerButtons,
    messengerList,
    returnToMessengers,
    isFlomniScriptLoaded,
  };
};

export default useFlomni;
