<template>
  <span class="new-label"> new </span>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.new-label {
  padding: 2px 8px;
  text-decoration: none;
  color: $WhiteColor;
  border-radius: 4px;
  background: $Attention;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  max-height: 18px;
  text-transform: uppercase;
}
</style>
