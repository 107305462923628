import { createScriptElement, getHeadElement } from '@/utils/dom.utils';

export const installADV = (src = import.meta.env.VITE_ADV_SRC) => {
  if (!src) {
    return;
  }

  const script = createScriptElement();

  script.id = 'advcakeAsync';

  script.text = `
  (function(a) {
        var b = a.createElement("script");
        b.async = 1;
        b.src = "${src}";
        a = a.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(b, a)
    })(document);
  `;

  const head = getHeadElement();

  head.appendChild(script);
};

export const sendDataToADV = (pageType = 2) => {
  window.advcake_data = window.advcake_data || [];
  window.advcake_data.push({
    pageType,
  });
};
