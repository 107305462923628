import { computed } from 'vue';
import { cdekHomePage } from '@/constants/global-variables';
import useSiteInfo from '@/composables/use-site-info';
import { usePageContext } from '@/composables/use-page-context';

const usePage = () => {
  const pageContext = usePageContext();

  const { currentLocale } = useSiteInfo();

  const isCityPage = computed(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !!pageContext.pageProps?.page?.city?.id;
  });

  const checkIsHomePage = (path: string) => {
    return (
      path === `/${currentLocale.value}` ||
      path === `/${currentLocale.value}/` ||
      path === `/${currentLocale.value}${cdekHomePage}`
    );
  };

  const isHomePage = computed(() => {
    if (pageContext?.urlPathname) {
      return checkIsHomePage(pageContext.urlPathname);
    }

    return false;
  });

  const isSearchResultsPage = computed(() => {
    return pageContext?.urlPathname?.startsWith(`/${currentLocale.value}/search`);
  });

  const isTrackingPage = computed(() => {
    return pageContext.urlOriginal.endsWith(`/tracking/`);
  });

  const isNewDesign = computed(() => {
    if (isCityPage.value) {
      return true;
    }

    return isHomePage.value;
  });

  const isHelpPage = computed(() => {
    return (
      !!pageContext?.routeParams?.['*'] &&
      pageContext.urlOriginal.startsWith(`/${currentLocale.value}/faq`)
    );
  });

  const isDarkPage = computed(() => {
    return (
      pageContext.urlOriginal.startsWith(`/${currentLocale.value}/payment-success`) ||
      pageContext.urlOriginal.startsWith(`/${currentLocale.value}/calculator_B2B`) ||
      pageContext.urlOriginal.startsWith(`/${currentLocale.value}/customs_duty`)
    );
  });

  const isBusinessPage = computed(() => {
    return pageContext.urlOriginal.startsWith(`/${currentLocale.value}/business`);
  });

  const isOfficesPage = computed(() => {
    return pageContext.urlOriginal.endsWith(`/offices/`);
  });

  return {
    isDarkPage,
    isCityPage,
    isHomePage,
    isNewDesign,
    isSearchResultsPage,
    isHelpPage,
    isTrackingPage,
    isOfficesPage,
    isBusinessPage,
  };
};

export default usePage;
