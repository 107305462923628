import { ComponentOptionsMixin } from 'vue';
import { useMenuStore } from '@/store/pinia/menu/menu';

const layoutHeaderMixin: ComponentOptionsMixin = {
  name: 'layout-header-mixin',
  setup() {
    const menuStore = useMenuStore();

    return {
      menuStore,
    };
  },
  data() {
    return {
      isMobileMenuOpened: false,
    };
  },
  methods: {
    clickModalOverlay() {
      this.$emitter.emit('mobile-menu-closed');
      this.menuStore.closeMobileMenu();
    },
  },
  mounted() {
    this.$emitter.on('mobile-menu-opened', () => {
      this.isMobileMenuOpened = true;
    });

    this.$emitter.on('mobile-menu-closed', () => {
      this.isMobileMenuOpened = false;
    });

    this.$emitter.on('mobile-menu-toggle', () => {
      this.isMobileMenuOpened = !this.isMobileMenuOpened;
    });
  },
  watch: {
    isMobileMenuOpened(value) {
      document.documentElement.classList.add(value ? 'mobile-menu-opening' : 'mobile-menu-closing');

      setTimeout(() => {
        document.documentElement.classList.remove(
          value ? 'mobile-menu-opening' : 'mobile-menu-closing',
        );

        if (value) {
          document.documentElement.classList.add('mobile-menu-opened');
        } else {
          // Я пока не придумал как это лучше типизировать
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.$emitter.emit('header-mobile-menu-closed');
          document.documentElement.classList.remove('mobile-menu-opened');
        }
      }, 250);
    },
  },
};

export default layoutHeaderMixin;
