<template>
  <component
    :is="component"
    :to="menu.url"
    :href="menu.url"
    :target="target"
    :prefetch="false"
    :rel="rel"
    @click="onClick"
  >
    <slot />
  </component>
</template>

<script>
/* global jQuery */

import { mapGetters } from 'vuex';
import BaseLink from '#root/src/components/BaseComponents/BaseLink.vue';
import { storeToRefs } from 'pinia';
import { reachGoal } from '@/utils/reach-goal';
import { useMenuStore } from '@/store/pinia/menu/menu';

export default {
  name: 'MenuItem',
  components: BaseLink,
  props: {
    menu: {
      required: true,
      type: Object,
    },
    tag: {
      type: [String, Number],
      required: false,
      default: 'a',
    },
    goal: {
      type: String,
      default: '',
    },
  },
  setup() {
    const menuStore = useMenuStore();

    const { isMobileMenuOpen, activePath } = storeToRefs(menuStore);

    return { isMobileMenuOpen, menuStore, activePath };
  },
  computed: {
    ...mapGetters({
      isLoggedInCabinet: 'isLoggedInCabinet',
      isChineSite: 'isChineSite',
      isSite: 'isSite',
    }),
    isChinaTrigger() {
      return this.isChineSite && this.menu.id === 105;
    },
    isExternalLink() {
      return this.menu.url && this.menu.url.startsWith('http');
    },
    target() {
      return this.isExternalLink ? '_blank' : '_self';
    },
    isCustomComponent() {
      return this.component === 'nuxt-link' || this.component === 'router-link';
    },
    component() {
      if (this.isChinaTrigger) {
        return 'button';
      }

      if (this.tag && this.tag !== 'a') {
        return this.isExternalLink ? 'a' : this.tag;
      }

      if (!this.isLoggedInCabinet) {
        return this.isExternalLink ? 'a' : BaseLink;
      }

      if (this.isSite) {
        return this.isExternalLink || this.menu.url.includes('/cabinet/') ? 'a' : BaseLink;
      }

      return [
        '/orders',
        '/cdekid',
        '/receipts',
        '/tickets',
        '/returns',
        '/offers',
        '/calculate',
      ].some((el) => this.menu.url.endsWith(el))
        ? BaseLink
        : 'a';
    },
    rel() {
      if (this.menu?.isExternal) {
        return 'external';
      }

      return this.menu?.url?.includes('/cabinet/') ? 'external' : null;
    },
  },

  mounted() {
    if (!this.isChinaTrigger) {
      return;
    }

    this.loadJquery();
  },

  methods: {
    toggleMobileMenu() {
      if (this.menu.children && this.menu.children.length > 0) {
        return;
      }

      this.closeMenu();
    },
    loadJquery() {
      if (!document.getElementById('jquery')) {
        const chartScript = document.createElement('script');
        chartScript.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
        chartScript.id = 'jquery';
        chartScript.async = true;
        chartScript.referrerpolicy = 'no-referrer';
        document.head.appendChild(chartScript);

        chartScript.onload = () => {
          this.loadFirstScript();
        };
      }
    },
    loadFirstScript() {
      if (!document.getElementById('chinaFirst')) {
        const chartScript = document.createElement('script');
        chartScript.src =
          'https://corp.cdek.ru/s/d41d8cd98f00b204e9800998ecf8427e-CDN/1w4yf8/810001/f726c0ccd5628076e8a005c87160ce33/2.2.4.7//download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=ad987a27';
        chartScript.id = 'chinaFirst';

        chartScript.type = 'text/javascript';
        chartScript.async = true;
        chartScript.referrerpolicy = 'no-referrer';
        document.head.appendChild(chartScript);

        chartScript.onload = () => {
          jQuery.ajax({
            url: 'https://corp.cdek.ru/s/6485a1252ff75c0e3773a77535b72841-T/1w4yf8/810001/f726c0ccd5628076e8a005c87160ce33/4.0.1/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=ru-RU&collectorId=ad987a27',
            type: 'get',
            cache: true,
            dataType: 'script',
          });
        };
      }
    },
    async onClick() {
      if (this.goal) {
        reachGoal(this.goal);
      }

      if (this.tag !== 'button') {
        if (this.target !== '_blank' && this.menu.id !== undefined) {
          this.menuStore.setActiveMenu(this.menu);
        }

        if (this.isMobileMenuOpen) {
          this.menuStore.closeMobileMenu();
        }
      }

      if (!this.isChinaTrigger) {
        return;
      }

      const trigger = document.getElementById('atlwdg-trigger');

      if (!trigger) {
        return;
      }

      trigger.click();
    },
  },
};
</script>

<style lang="scss">
a[id='atlwdg-trigger'] {
  display: none;
}
</style>
