<template>
  <slide-left-transition>
    <div v-if="selectedMenu" class="mobile-drawer-second-level">
      <mobile-drawer-header>
        <button class="mobile-drawer-second-level__header" @click="$emit('second-level-closed')">
          <mobile-drawer-back-icon class="mobile-drawer-second-level__back" />

          {{ selectedMenu.title }}
        </button>
      </mobile-drawer-header>

      <ul class="mobile-drawer-second-level-menu">
        <li
          v-for="(menu, index) in selectedMenu.children"
          :key="index"
          class="mobile-drawer-second-level-menu__item"
        >
          <deep-menu-item :menu="menu" :has-icons="hasIcons" />
        </li>
      </ul>
    </div>
  </slide-left-transition>
</template>
<script setup></script>
<script>
import MobileDrawerHeader from '#root/src/components/MobileDrawer/MobileDrawerHeader.vue';
import SlideLeftTransition from '#root/src/components/Transitions/SlideLeftTransition.vue';
import DeepMenuItem from '#root/src/components/MobileDrawer/MobileDrawerDeepMenuItem.vue';
import MobileDrawerBackIcon from './icons/mobile-drawer-back-icon.svg';

export default {
  name: 'MobileDrawerSecondLevel',
  components: {
    DeepMenuItem,
    SlideLeftTransition,
    MobileDrawerHeader,
    MobileDrawerBackIcon,
  },
  props: {
    selectedMenu: {
      type: Object,
      default: null,
    },
  },
  emits: ['second-level-closed'],
  computed: {
    hasIcons() {
      return !!this.selectedMenu.children.find((el) => !!el.icon);
    },
  },
};
</script>

<style lang="scss">
.mobile-drawer-second-level {
  position: fixed;
  z-index: 11;
  top: 0;
  right: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #fff;

  &__header {
    display: flex;
    align-items: center;
    color: $Bottom;
    font-family: Roboto, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  &__back {
    margin-right: 12px;
  }

  &-menu {
    margin-top: 16px;

    &__item {
      list-style: none;
      margin: 0;
    }
  }
}
</style>
