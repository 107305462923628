<template>
  <div class="mobile-drawer-menu-item">
    <menu-item
      :menu="rootMenu"
      class="mobile-drawer-menu-item__link mobile-drawer-menu-item__link--root"
    >
      <div class="mobile-drawer-menu-item__root_wrapper">
        {{ rootMenu.title }}
        <menu-dropdown-arrow :is-open="false" class="mobile-drawer-menu-item__arrow" />
      </div>
    </menu-item>

    <ul class="mobile-drawer-menu-item-submenu">
      <li
        v-for="(submenu, index) in rootMenu.children"
        :key="index"
        class="mobile-drawer-menu-item-submenu__item"
      >
        <menu-item
          :menu="submenu"
          :tag="submenu.children && submenu.children.length && 'button'"
          class="mobile-drawer-menu-item__link"
          @click="showChildList(submenu)"
        >
          <span>
            {{ submenu.title }}
            <menu-new-label v-if="submenu.isNew" class="mobile-drawer-menu-item__label" />
          </span>

          <menu-dropdown-arrow
            v-if="submenu.children && submenu.children.length"
            :is-open="false"
            class="mobile-drawer-menu-item__arrow"
          />
        </menu-item>
      </li>
    </ul>
  </div>
</template>

<script>
import MenuNewLabel from '@/components/Menu/MenuNewLabel.vue';
import MenuDropdownArrow from '@/components/Menu/MenuDropdownArrow.vue';
import MenuItem from '@/components/Menu/MenuItem.vue';

export default {
  name: 'MobileDrawerMenuItem',

  components: {
    MenuDropdownArrow,
    MenuNewLabel,
    MenuItem,
  },
  props: {
    rootMenu: {
      type: Object,
      required: true,
    },
  },
  emits: ['expanded'],

  methods: {
    showChildList(submenu) {
      this.$emit('expanded', submenu);
    },
  },
};
</script>

<style scoped lang="scss">
.mobile-drawer-menu-item {
  &__link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    color: $base-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &--active {
      color: $primary-base;
    }

    &--root {
      width: 100%;
      padding: 10px 16px;
      background: $Tertiary_10;
      margin-bottom: 8px;
      box-sizing: border-box;
    }
  }

  &__root_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__arrow {
    transform: rotate(-90deg);
  }

  &__label {
    margin-left: 8px;
    text-decoration: none;
  }

  &-submenu {
    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 16px;
      color: #232323;
      font-family: Roboto, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */

      &:last-child {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
