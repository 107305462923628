export const COUNTRIES = [
  {
    url: 'https://cdek.ru',
    code: 'ru',
  },
  {
    url: 'https://cdek.by',
    code: 'by',
  },
  {
    url: 'https://cdek.kz',
    code: 'kz',
  },
  {
    url: 'https://cdek-express.cn',
    code: 'cn',
  },
  {
    url: 'https://cdek-am.com',
    code: 'am',
  },
  {
    url: 'https://cdek.kg',
    code: 'kg',
  },
];
