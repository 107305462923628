import { cdekConsoleError } from '@/utils/console-wrapper.ts';
import { getItemLS, setItemLS } from '@/utils/local-storage.utils.js';

export function addSlash(url) {
  if (url.startsWith('http')) {
    return url;
  }
  return url.charAt(0) !== '/' ? `/${url}` : url;
}

export function mappingMenusForBuild(menu, mappingMenu, currentMenu, depth, currentPath) {
  return {
    ...menu,
    active: currentMenu.depth === depth ? currentPath === menu.url : menu.url === mappingMenu.url,
  };
}

export function getMenuBuilt(currentMenu, rootMenus, reverseMapping) {
  let submenus = [];
  let menus = [];
  if (currentMenu) {
    let { depth } = currentMenu;
    let mappingMenu = { ...currentMenu };
    while (depth >= 0) {
      if (depth > 0) {
        submenus.unshift([
          // eslint-disable-next-line no-loop-func,@typescript-eslint/no-loop-func
          ...mappingMenu.menus.map((m) =>
            mappingMenusForBuild(m, mappingMenu, currentMenu, depth, currentMenu.url),
          ),
        ]);
      } else {
        menus = [
          ...mappingMenu.menus.map(
            // eslint-disable-next-line no-loop-func,@typescript-eslint/no-loop-func
            (m) => mappingMenusForBuild(m, mappingMenu, currentMenu, -1, currentMenu.url),
          ),
        ];
        if (mappingMenu.menuChildren && mappingMenu.menuChildren.length && !submenus.length) {
          submenus.push(mappingMenu.menuChildren);
        }
      }
      if (mappingMenu.parentId) {
        mappingMenu = { ...reverseMapping[mappingMenu.parentId] };
      }
      depth -= 1;
    }
  } else if (rootMenus.length) {
    menus = [...rootMenus];
    submenus = [menus[0].children];
  }
  if (submenus.length === 0 && rootMenus.length) {
    submenus = [rootMenus[0].children];
  }

  return {
    menus,
    submenus,
  };
}

export function removeLastPath(path) {
  const splitPath = path.split('/');
  splitPath.pop();
  return splitPath.join('/');
}

export function trimPath(path) {
  if (path.charAt(0) === '/') {
    path = path.substr(1, path.length - 1);
  }
  if (path.charAt(path.length - 1) === '/') {
    path = path.substr(0, path.length - 2);
  }
  return path;
}

export function getReverseMapping(menus, mapping, depth, parentId) {
  mapping = mapping || {};
  depth = typeof depth === 'number' ? depth + 1 : 0;
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i];
    mapping[menu.id] = {
      depth,
      parentId,
      url: menu.url,
      menus: [...menus],
      icon: menu.icon,
      menuChildren: menu.children,
    };
    if (menu.children && menu.children.length) {
      mapping = getReverseMapping(menu.children, mapping, depth, menu.id);
    }
  }
  return mapping;
}

export function updateUrlMenu(menus) {
  for (const menu of menus) {
    menu.url = addSlash(menu.url);
    if (menu.children && menu.children) {
      menu.children = updateUrlMenu(menu.children);
    }
  }
  return menus;
}

const CITY_KEY = 'localstorage:city_key';

export function getLocalCity() {
  try {
    const cityString = getItemLS(CITY_KEY);

    return cityString ? JSON.parse(cityString) : null;
  } catch (error) {
    cdekConsoleError(error);
    return null;
  }
}

export function saveLocalCity(city) {
  try {
    setItemLS(CITY_KEY, JSON.stringify(city));
  } catch (error) {
    cdekConsoleError(error);
  }
}
