<template>
  <div
    v-if="isShowFlomni"
    ref="helpCustomerWrapper"
    class="help-customer-wrapper"
    :class="{
      'help-customer-wrapper--opened': open,
      'help-customer-wrapper--flomni': isFlomni,
      'help-customer-wrapper--loaded': loaded,
    }"
    :style="{ right }"
  >
    <help-customer-container
      v-if="enableCustomerContainer"
      class="help-customer-wrapper__container"
      :opened="open"
      :loaded="loaded"
      :widget="widget"
      @close="onClose"
    />
    <help-customer-trigger :opened="open" :widget="widget" @toggle="onToggle" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import HelpCustomerTrigger from '@/components/HelpCustomer/HelpCustomerTrigger.vue';
import HelpCustomerContainer from '@/components/HelpCustomer/HelpCustomerContainer.vue';
import { cdekConsoleError } from '@/utils/console-wrapper';
import { ref } from 'vue';
import { getBodyElement } from '@/utils/dom.utils';
import useFlomni from '@/composables/use-flomni';
import useSettings from '@/composables/use-settings';

export default {
  name: 'HelpCustomerWrapper',
  components: { HelpCustomerContainer, HelpCustomerTrigger },
  setup() {
    const { installFlomni, isFlomniScriptLoaded } = useFlomni();

    const initRight = '4px';

    const buttonRightInt = parseInt(initRight, 10) || 0;

    const right = ref(initRight);

    const body = getBodyElement();

    const { getSettingsGroup } = useSettings();

    const { displayChatOnly } = getSettingsGroup('all');

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const bodyPaddingRightInt = parseInt(body.style.paddingRight, 10) || 0;

        right.value = `${buttonRightInt + bodyPaddingRightInt}px`;
      });
    });

    observer.observe(body, { attributes: true, attributeFilter: ['style'] });

    return {
      right,
      enableCustomerContainer: !displayChatOnly,
      installFlomni,
      isFlomniScriptLoaded,
    };
  },
  data() {
    return {
      open: false,
      widget: null,
      loaded: false,
      updated: false,
    };
  },
  computed: {
    ...mapState('flomni', ['isExternalOpened']),
    ...mapGetters({
      currentLocaleISO: 'currentLocaleISO',
      isFlomni: 'flomni/displayChat',
      stateFlomni: 'flomni/state',
      isYaMapsBalloonOpened: 'flomni/isYaMapsBalloonOpened',
      consentToUseOfCookies: 'cookie-alert/consentToUseOfCookies',
    }),
    isShowFlomni() {
      return !this.isYaMapsBalloonOpened && this.isFlomniScriptLoaded;
    },
  },
  watch: {
    currentLocaleISO() {
      this.loadContent();
    },
    stateFlomni(state) {
      if (!this.enableCustomerContainer) {
        if (state === 'closed') {
          this.open = false;
        }

        return;
      }

      if (state === 'open') {
        this.onClose();
      }
    },
    $route() {
      this.loadContent();
    },
    consentToUseOfCookies(val) {
      if (val) {
        this.$el?.style?.removeProperty('bottom');
      }
    },
    /**
     * Открытие виджета из любого места через стор
     * flomni/setIsExternalOpened
     * flomni/toggleIsExternalOpened
     * @param {Boolean} val
     */
    isExternalOpened(val) {
      this.onToggle(val);
    },
  },
  mounted() {
    this.$emitter.on('flomni-mounted', () => {
      this.checkFlomniEl();
      this.installFlomni();

      if (!this.consentToUseOfCookies) {
        this.handleBottomPlacement();

        window.addEventListener('resize', this.handleBottomPlacement);
      }
    });

    if (this.isFlomni) {
      this.$store.commit('flomni/onInit', { displayChat: false });
    }

    this.setIsHelpCustomerLoaded(true);
  },
  created() {
    this.loadContent();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleBottomPlacement);
  },
  methods: {
    ...mapMutations('flomni', ['setIsHelpCustomerLoaded', 'setIsExternalOpened']),
    handleBottomPlacement() {
      const cookieEl = document.querySelector('.cookie-alert');

      if (!cookieEl?.clientHeight || !this.$el?.style?.bottom) {
        return;
      }

      this.$el.style.bottom = `${cookieEl.clientHeight + 12}px`;
    },
    checkFlomniEl() {
      const layout = document.getElementById('__layout');
      const el = document.getElementById('flomni');

      if (el) {
        el.classList.add('hidden-flomni');
        return;
      }

      const flomniEl = document.createElement('div');
      flomniEl.setAttribute('id', 'flomni');
      flomniEl.classList.add('hidden-flomni');
      layout.appendChild(flomniEl);
    },
    handleUzToggle(open) {
      if (open) {
        this.open = true;
      }

      if (window.$flomni && window.$flomni.chat) {
        window.$flomni.chat.switchToggler(open);
      }
    },
    onToggle(open) {
      if (!this.enableCustomerContainer) {
        this.handleUzToggle(open);
        return;
      }

      this.open = open;

      if (open && window.$flomni && window.$flomni.chat && window.$flomni.chat.togglerState()) {
        window.$flomni.chat.switchToggler(false);
      }

      if (open !== this.isExternalOpened) {
        this.setIsExternalOpened(open);
      }
    },
    onClose() {
      this.open = false;
      this.setIsExternalOpened(false);
    },
    loadContent() {
      this.updated = true;

      this.$restApi
        .helpWidget(
          {
            locale: this.currentLocaleISO,
          },
          this.$route.$path,
        )
        .then((res) => {
          let id = 0;
          res.questions =
            res.questions?.map((i) => ({
              ...i,
              // eslint-disable-next-line no-plusplus
              id: id++,
            })) || [];
          this.widget = res;
        })
        .catch((e) => {
          cdekConsoleError(e);
        })
        .finally(() => {
          this.updated = false;
          this.loaded = true;
        });
    },
  },
  destroy() {
    if (window.$flomni) {
      window.$flomni.chat && window.$flomni.chat.destroy();
    }
  },
};
</script>
<style lang="scss">
.hidden-flomni {
  #flomni-preloading,
  #flomni-toggler {
    visibility: hidden;
    pointer-events: none;
  }
}
</style>
<style scoped lang="scss">
.help-customer-wrapper {
  position: fixed;
  z-index: 50;
  bottom: 12px;
  display: flex;
  visibility: hidden;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;

  &--opened {
    z-index: 99;
  }

  &--flomni {
    right: 68px;
  }

  &__container {
    margin-bottom: 16px;
  }

  &--loaded {
    visibility: visible;
  }

  @media (min-width: $tablet) {
    bottom: 12px;
  }
}
</style>
