<template>
  <transition
    name="slide-left-transition"
    @before-enter="addDisableScrollClass"
    @after-enter="removeDisableScrollClass"
    @before-leave="addDisableScrollClass"
    @after-leave="removeDisableScrollClass"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'SlideLeftTransition',
  methods: {
    addDisableScrollClass() {
      document.documentElement.classList.add('slide-left-overflow-scroll');
    },
    removeDisableScrollClass() {
      document.documentElement.classList.remove('slide-left-overflow-scroll');
    },
  },
};
</script>
<style>
.slide-left-overflow-scroll {
  overflow-x: hidden;
}
</style>
<style scoped>
.slide-left-transition-enter-active,
.slide-left-transition-leave-active {
  transition: transform 0.2s ease;
}

.slide-left-transition-enter-from,
.slide-left-transition-leave-to {
  transform: translateX(100%);
  transition: all 0.2s ease-in 0s;
}
</style>
