<template>
  <vue-final-modal
    ref="modal"
    :classes="['select-country']"
    content-class="select-country__content"
    :name="name"
  >
    <mobile-close-line class="select-country__mobile-controls" @swipe-down="onSwipeDown" />

    <button type="button" class="select-country__close" @click="onClose">
      <img :src="closeIcon" alt="" />
    </button>

    <div class="select-country__title">
      {{ $t('selectCountry.title') }}
    </div>
    <div class="select-country__items">
      <div v-for="country in $options.COUNTRIES" :key="country.code" class="select-country__item">
        <base-link class="select-country__item-link" :href="country.url">
          {{ $t(`selectCountry.${country.code}`) }}
        </base-link>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import stopScrollOpenModal from '@/components/Modal/stop-scroll-open-modal-mixin';
import MobileCloseLine from '@/components/MobileCloseLine/MobileCloseLine.vue';
import BaseLink from '@/components/BaseComponents/BaseLink.vue';
import { COUNTRIES } from '@/constants/select-countries';
import closeIcon from '@/assets/img/close.svg?url';

export default {
  name: 'SelectCountryModal',
  components: {
    BaseLink,
    MobileCloseLine,
  },
  mixins: [stopScrollOpenModal],
  COUNTRIES,
  data() {
    return {
      closeIcon,
      name: 'select-country-modal',
    };
  },
  methods: {
    onClose() {
      this.$modal.hide(this.name);
    },
    onSwipeDown() {
      this.onClose();
    },
  },
};
</script>
<style lang="scss">
.select-country {
  display: flex;
  align-items: flex-start;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    transition: $primary-transition;
    background-color: #fff;
    position: relative;
  }

  @include media-min-lg {
    align-items: center;

    &__content {
      width: auto;
      margin: auto;
      height: auto;
      padding: 32px;
      border-radius: 8px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    }
  }
}
</style>

<style scoped lang="scss">
.select-country {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: $primary-transition;
  background-color: #fff;

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    outline: none;

    @include media-min-lg {
      top: 18px;
      right: 13px;
    }
  }

  &__mobile-controls {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
    padding: 16px;

    @include media-min-lg {
      font-size: 24px;
      line-height: 32px;
      box-shadow: none;
      padding: 0;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    @include media-min-lg {
      flex-direction: row;
      margin-top: 24px;
    }
  }

  &__item {
    padding: 12px 0 12px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;

    @include media-min-lg {
      padding: 0 16px 0 0;
    }
  }

  &__item-link {
    text-decoration: none;
    color: #000;
  }

  &__item-link:hover {
    color: #158e3a;
  }
}
</style>
